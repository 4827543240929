<template>
  <Navbar :user="user" />
    <div class="container pb-100 pt-100" v-if="!isLoading">
      <div class="section-title">
        <h2>Edit {{ form.title }}</h2>
      </div>
        <form id="contactForm" @submit.prevent="editTest">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-8">
              <div class="form-group text-start">
                <label for="name" class="text-start">Title</label>
                <input type="text" v-model="form.title" id="name" class="form-control"/>
                <div v-if="errors.title" class="text-small text-danger text-start">{{ errors.title[0] }}</div>
              </div>
            </div>
            <div class="col-lg-8 col-md-8">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Description</label>
                <ckeditor :editor="editor" v-model="form.description"></ckeditor>
                <div v-if="errors.description" class="text-small text-danger text-start">{{ errors.description[0] }}</div>
              </div>
            </div>
            <div class="col-lg-8 col-md-8">
              <button type="submit" class="btn default-btn" disabled v-if="submitting">
                <span class="label">{{ value }}</span>
              </button>
              <button type="submit" class="default-btn btn" style="pointer-events: all; cursor: pointer" v-else>
                <span class="label">Create Chapter</span>
              </button>
            </div>
          </div>
        </form>
    </div>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import axios from 'axios'
import Loading from '@/components/Loading.vue'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
export default {
  components: { Navbar,Loading, ckeditor: CKEditor.component },
  data(){
    return {
      isLoading: true,
      submitting: false,
      editor: ClassicEditor,
      token: localStorage.getItem('user_data'),
      form:{
        chapter:'', description:"",
      },
      user: {},
      test:{},
      errors: {}
    }
  },
  methods:{
    editTest(){
        this.submitting = true
        this.value = 'Please Wait...'
        let id = this.$route.params.id
        let test = this.$route.params.test
        axios.post('https://apitraining.vipawaworks.com/api/test/update/' + test,this.form).then(response =>{
        this.test = response.data
            this.$router.push({ name: 'CourseDashboard', params: {id: id}})
        }).catch((errors) => {
            this.errors = errors.response.data.errors
            this.submitting = false
        })
    },
    getTest(){
      let id = this.$route.params.test
      axios.get('https://apitraining.vipawaworks.com/api/test/profile/' + id).then(response => {
        this.form = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          localStorage.removeItem('user_data')
          this.$router.push({ name: 'Login'})
        }
      }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    document.title = `Create Quiz - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser() 
    this.getTest()
  },

};
</script>

<style>
</style>